import "core-js/modules/es.string.fixed.js";
import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-0f556c4c"), n = n(), _popScopeId(), n;
};

var _hoisted_1 = {
  key: 0,
  style: {
    "height": "0.9rem"
  }
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_top = _resolveComponent("top");

  var _component_serve_nav = _resolveComponent("serve-nav");

  var _component_serve_before = _resolveComponent("serve-before");

  var _component_serve_question = _resolveComponent("serve-question");

  var _component_serve_ing = _resolveComponent("serve-ing");

  var _component_serve_after = _resolveComponent("serve-after");

  var _component_common_footer1 = _resolveComponent("common-footer1");

  var _component_common_back_top = _resolveComponent("common-back-top");

  return _openBlock(), _createElementBlock("div", null, [_createVNode(_component_top, {
    id: "serve-top"
  }), _createVNode(_component_serve_nav, {
    onBtn: $options.btn,
    id: "serve-nav",
    class: _normalizeClass({
      fixed: $data.fixed
    }),
    type: $data.navType
  }, null, 8, ["onBtn", "class", "type"]), $data.fixed ? (_openBlock(), _createElementBlock("div", _hoisted_1)) : _createCommentVNode("", true), _createVNode(_component_serve_before, {
    class: "serve-before"
  }), _createVNode(_component_serve_question), _createVNode(_component_serve_ing, {
    class: "serve-ing"
  }), _createVNode(_component_serve_after, {
    class: "serve-after"
  }), _createElementVNode("div", {
    style: _normalizeStyle({
      margin: '2rem 0 .4rem',
      height: '.02rem',
      background: '#E5E6EB'
    })
  }, null, 4), _createVNode(_component_common_footer1), _createVNode(_component_common_back_top)]);
}